import Sortable, { AutoScroll } from 'sortablejs/modular/sortable.core.esm.js';
import { trackCompareBearings } from '../analytics/cms';

Sortable.mount( new AutoScroll() );

document.addEventListener( 'DOMContentLoaded', () => {
	const headings = document.getElementsByClassName( 'm-compare-bearings__headings' )[ 0 ];
	const tables = document.querySelector( '.m-compare-bearings__tables' );

	if ( ! headings || ! tables ) {
		return;
	}

	const tableRows = tables.querySelectorAll( 'table tbody tr' );
	const sortableTableRowsInstances = [];

	const sortableHeadings = Sortable.create( headings, {
		handle: '.icon-drag',
		ghostClass: 'm-compare-bearings__heading--ghost',
		dragClass: 'm-compare-bearings__heading--drag',
		onUpdate() {
			const order = sortableHeadings.toArray();
			sortableTableRowsInstances.forEach( ( i ) => {
				i.sort( order, true );
			} );
		},
	} );

	tableRows.forEach( ( r ) => {
		const sortableInstance = Sortable.create( r, {
			disabled: true,
		} );
		sortableTableRowsInstances.push( sortableInstance );
	} );

	document.querySelectorAll( '.js-remove-bearing-item' ).forEach( ( $el ) => {
		if ( headings.children.length > 2 ) {
			const hideClass = 'invisible';
			$el.addEventListener( 'click', () => {
				const currentHeading = $el.closest( '.m-compare-bearings__heading' );
				const currentHeadingIndex = Array.from( headings.children ).findIndex(
					( heading ) =>
						heading.getAttribute( 'data-id' ) == currentHeading.getAttribute( 'data-id' ),
				);
				tableRows.forEach( ( r ) => {
					const table = r.closest( 'table' );
					const colgroup = table.querySelector( 'colgroup' );
					const thead = table.querySelector( '.m-compare-bearings__desktop-table-head tr th' );

					colgroup.removeChild( colgroup.querySelector( 'col' ) );
					thead.setAttribute( 'colspan', colgroup.children.length );
					r.removeChild(
						r.children[
							currentHeadingIndex
						],
					);

					let count = 0;
					for(const value of r.children) {
						if (value.innerHTML.trim() === "") {
							count++;
						}
					}
					if (count === r.children.length) {
						table.remove();
					}

				} );
				headings.removeChild( currentHeading );
				window._timken.compareBearingsData.splice( currentHeadingIndex, 1 );
				trackCompareBearings();
				if ( headings.children.length < 3 ) {
					headings.querySelectorAll( '.js-remove-bearing-item' ).forEach(
						( el ) => el.classList.add( hideClass ),
					);
				}
			} );
			$el.classList.remove( hideClass );
		}
	} );

	const bindSyncScrolling = ( headings, tables ) => {
		const sync = ( headings, tables ) => () =>
				(
					headings.scrollTop = tables.scrollTop,
					headings.scrollLeft = tables.scrollLeft
				)
		;
		tables.onscroll = sync( headings, tables );
		headings.onscroll = sync( tables, headings );
	};

	bindSyncScrolling( headings, tables );

	trackCompareBearings();
} );
